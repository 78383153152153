import React, { useEffect, useState } from 'react';
import {useParams, Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@material-ui/core';

const NewPublicidad = () => {
    const model = {
        'id': 0,
        'title': '',
        'description': '',
    }
    
    const [error, setError] = useState(null);
    const { id } = useParams();
    
    const [fields, setFields] = React.useState({
        title: '',
        description: '',
        posiciones: [], // Changed from posicion to posiciones array
        imagen: '',
        enlace: '',
        restaurant_ids: [], // Changed from restaurant_id to restaurant_ids array
    });

    const [restaurants, setRestaurants] = useState([]); // Added restaurants state
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Fetch restaurants when component mounts
        fetch('https://api21.com.do/api/v1/listRestaurants')
            .then(response => response.json())
            .then(result => {
                if (result && result.data) {
                    setRestaurants(result.data);
                }
            })
            .catch(error => {
                console.error('Error loading restaurants:', error);
                toast.error('Error al cargar los restaurantes');
            });
    }, []);

    const positionOptions = [
        'en espera',
        'confirmación',
        'recordatorio',
        'satisfacción'
    ];

    const updateFields = (field, value) => {
        const newFields = {
            ...fields,
        };
        newFields[field] = value;
        setFields(newFields);
    };
    
    function validateForm() {
        if (!fields.title.trim()) {
            toast.error('El título es requerido');
            return false;
        }
        if (!fields.description.trim()) {
            toast.error('La descripción es requerida');
            return false;
        }
        if (fields.posiciones.length === 0) {
            toast.error('Debe seleccionar al menos una posición');
            return false;
        }
        if (fields.restaurant_ids.length === 0) {
            toast.error('Debe seleccionar al menos un restaurante');
            return false;
        }
        if (!fields.enlace.trim()) {
            toast.error('El enlace es requerido');
            return false;
        }
        if (!fields.imagen && !id) {
            toast.error('La imagen es requerida');
            return false;
        }
        if (fields.imagen && typeof fields.imagen !== 'string') {
            const maxSize = 2 * 1024 * 1024; // 2MB en bytes
            if (fields.imagen.size > maxSize) {
                toast.error('La imagen no debe superar los 2MB');
                return false;
            }
        }
        return true;
    }
    
    function addNewPublicidad() {
        if (!validateForm()) return;
        
        try {
            const body = new FormData();
            body.append('title', fields.title);
            body.append('description', fields.description);
            fields.posiciones.forEach((pos, index) => {
                body.append(`posiciones[${index}]`, pos);
            });
            body.append('imagen', fields.imagen);
            body.append('enlace', fields.enlace);
            // Append each restaurant ID
            fields.restaurant_ids.forEach((id, index) => {
                body.append(`restaurant_ids[${index}]`, id);
            });

            fetch('https://api21.com.do/api/v1/publicArtisng/created', {
                method: 'POST',
                body: body
            })
            .then(response => response.json())
            .then((response) => {
                if (response.success === 1) {
                    toast.success('Publicidad creada exitosamente');
                    setTimeout(() => {
                        document.location = '/publicidad';
                    }, 1500);
                } else {
                    setError(response.data || 'Error al crear la publicidad');
                    toast.error(response.data || 'Error al crear la publicidad');
                }
            })
            .catch((err) => {
                const errorMsg = 'Error al crear la publicidad. Por favor, inténtalo de nuevo.';
                setError(errorMsg);
                toast.error(errorMsg);
                console.error('Error al crear la publicidad:', err);
            });
        } catch (err) {
            const errorMsg = 'Error al crear la publicidad. Por favor, inténtalo de nuevo.';
            setError(errorMsg);
            toast.error(errorMsg);
            console.error('Error al crear la publicidad:', err);
        }
    }

    function updatePublicidad() {
        if (!validateForm()) return;
        
        try {
            const body = new FormData();
            body.append('title', fields.title);
            body.append('description', fields.description);
            fields.posiciones.forEach((pos, index) => {
                body.append(`posiciones[${index}]`, pos);
            });
            if (fields.imagen && typeof fields.imagen !== 'string') {
                body.append('imagen', fields.imagen);
            }
            body.append('enlace', fields.enlace);
            // Append each restaurant ID
            fields.restaurant_ids.forEach((id, index) => {
                body.append(`restaurant_ids[${index}]`, id);
            });

            fetch(`https://api21.com.do/api/v1/publicArtisng/edit/${id}`, {
                method: 'PUT',
                body: body
            })
            .then(response => response.json())
            .then((response) => {
                if (response.success === 1) {
                    toast.success('Publicidad actualizada exitosamente');
                    setTimeout(() => {
                        document.location = '/publicidad';
                    }, 1500);
                } else {
                    setError(response.data || 'Error al actualizar la publicidad');
                    toast.error(response.data || 'Error al actualizar la publicidad');
                }
            })
            .catch((err) => {
                const errorMsg = 'Error al actualizar la publicidad. Por favor, inténtalo de nuevo.';
                setError(errorMsg);
                toast.error(errorMsg);
                console.error('Error al actualizar la publicidad:', err);
            });
        } catch (err) {
            const errorMsg = 'Error al actualizar la publicidad. Por favor, inténtalo de nuevo.';
            setError(errorMsg);
            toast.error(errorMsg);
            console.error('Error al actualizar la publicidad:', err);
        }
    }

    useEffect(() => {
        if (id) {
            fetch(`https://api21.com.do/api/v1/publicArtisng/details/${id}`)
            .then(response => response.json())
            .then(data => {
                if (data && data.success === 1) {
                    const matchingImage = data.data;
                    setFields(prevFields => ({
                        ...prevFields,
                        title: matchingImage.titulo,
                        description: matchingImage.descripcion,
                        posiciones: matchingImage.posiciones || [], // Changed to handle array
                        imagen: matchingImage.imagen,
                        enlace: matchingImage.enlace,
                        restaurant_ids: matchingImage.restaurant_ids || [], // Changed to handle array
                    }));
                }
            })
            .catch(error => {
                console.error('Error al obtener los datos de imagen:', error);
            });
        }
    }, [id]);

    function notify() {
        toast.success('Información actualizada con éxito', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
    }

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

    const renderView = () => (
        <>
            <div className="w-100 mx-2">
                <div className="RestaurantOffer-form-container mr-2">
                    <div style={{ color: 'black', fontFamily: 'roboto', fontSize: '16px' }}>
                        <span>
                            <b>Detalles de la publicidad</b>
                            <br />
                        </span>
                    </div>
                    <div className="RestaurantOffer__form">
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="title"
                                label="Título"
                                value={fields.title}
                                onChange={(e) => updateFields('title', e.target.value)}
                            />
                        </div>
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="description"
                                label="Descripción"
                                value={fields.description}
                                onChange={(e) => updateFields('description', e.target.value)}
                            />
                        </div>
                        <div className="w100 mb-1">
                            <label className="form-label">Posiciones</label>
                            <div style={{ 
                                maxHeight: '150px', 
                                overflowY: 'auto', 
                                border: '1px solid #ced4da',
                                borderRadius: '4px',
                                padding: '10px'
                            }}>
                                {positionOptions.map((position) => (
                                    <div key={position} className="form-check mb-2">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`position-${position}`}
                                            checked={fields.posiciones.includes(position)}
                                            onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                updateFields('posiciones', 
                                                    isChecked 
                                                        ? [...fields.posiciones, position]
                                                        : fields.posiciones.filter(pos => pos !== position)
                                                );
                                            }}
                                        />
                                        <label 
                                            className="form-check-label" 
                                            htmlFor={`position-${position}`}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {position}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <small className="text-muted mt-2 d-block">
                                Selecciona una o más posiciones haciendo clic en las casillas
                                {fields.posiciones.length > 0 && fields.restaurant_ids.length > 0 && (
                                    <span className="ms-2 text-info">
                                        (Se crearán {fields.posiciones.length * fields.restaurant_ids.length} banners en total)
                                    </span>
                                )}
                            </small>
                        </div>
                        <div className="w100 mb-1">
                            <label className="form-label">Restaurantes</label>
                            <div className="mb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Buscar restaurantes..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div style={{ 
                                maxHeight: '200px', 
                                overflowY: 'auto', 
                                border: '1px solid #ced4da',
                                borderRadius: '4px',
                                padding: '10px'
                            }}>
                                {restaurants
                                    .filter(restaurant => 
                                        restaurant.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    )
                                    .sort((a, b) => {
                                        if (a.id === 378) return -1;
                                        if (b.id === 378) return 1;
                                        return 0;
                                    })
                                    .map((restaurant) => (
                                    <div key={restaurant.id} className="form-check mb-2">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`restaurant-${restaurant.id}`}
                                            checked={fields.restaurant_ids.includes(restaurant.id)}
                                            onChange={(e) => {
                                                const isChecked = e.target.checked;
                                                updateFields('restaurant_ids', 
                                                    isChecked 
                                                        ? [...fields.restaurant_ids, restaurant.id]
                                                        : fields.restaurant_ids.filter(id => id !== restaurant.id)
                                                );
                                            }}
                                        />
                                        <label 
                                            className="form-check-label" 
                                            htmlFor={`restaurant-${restaurant.id}`}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {restaurant.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <small className="text-muted mt-2 d-block">
                                Selecciona uno o más restaurantes haciendo clic en las casillas
                            </small>
                        </div>
                        <div className="w100 mb-1">
                            <input
                                type="file"
                                className="w100"
                                id="imagen"
                                accept="image/*"
                                label="Imagen"
                                onChange={(e) => updateFields('imagen', e.target.files[0])}
                            />
                            {fields.imagen && (
                                <p>Archivo seleccionado: {typeof fields.imagen === 'string' ? fields.imagen.split('/').pop() : fields.imagen.name}</p>
                            )}
                        </div>
                        <div className="w100 mb-1">
                            <TextField
                                className="w100"
                                id="enlace"
                                label="Enlace"
                                value={fields.enlace}
                                onChange={(e) => updateFields('enlace', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    const desktop = () => (
        <article>
            <div style={{ display: "flex", alignItems: "center", padding: "29px 40px" }}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"
                }}>
                    {id ? 'EDITAR PUBLICIDAD' : 'NUEVA PUBLICIDAD'}
                </label>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}>
            </div>
            <div style={{ display: "flex", padding: "29px 36px 29px 40px", height: 'calc(100% - 150px)', overflow: 'auto' }}>
                <div style={{ display: "flex", width: '30%', justifyContent: 'space-between' }}>
                    <div style={{ textAlign: "left" }}>
                        <p style={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            color: 'black',
                            letterSpacing: '0.5px',
                            width: '80%',
                            paddingTop: '40px'
                        }}>
                            {id ? 'Editar los datos de la publicidad.' : 'Crear una nueva publicidad.'}
                        </p>
                    </div>
                    <div style={{
                        width: "0px",
                        height: "630px",
                        margin: "56px 0px 70px",
                        opacity: "0.15",
                        border: "solid 0.5px #000001",
                        paddingLeft: "0px",
                        paddingRight: "0px"
                    }}>
                    </div>
                </div>
                <div style={{ display: 'flex', width: '70%' }}>
                    <form style={{
                        textAlign: "left",
                        paddingLeft: "40px",
                        width: '100%'
                    }}>
                        <div className="W100">
                            {renderView()}
                        </div>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end" style={{ paddingTop: '20px' }}>
                            <Link to="/publicidad" className="btn btn-lg mt-5" style={{ width: '162px', boxShadow: 'none', fontFamily: 'Commuters Sans SemiBold' }}>
                                CANCELAR
                            </Link>
                            <button 
                                className="btn btn-primary btn-lg mt-5" 
                                type="button" 
                                style={{ width: '162px' }} 
                                onClick={() => { id ? updatePublicidad() : addNewPublicidad() }}
                            >
                                {id ? 'ACTUALIZAR' : 'GUARDAR'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    );

    const mobile = () => desktop(); // Mismo diseño para móvil por ahora

    return isDesktopOrLaptop ? desktop() : mobile();
}

export default NewPublicidad;
