import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import ModalComponent, { ModalFunctions } from '../components/ModalComponent'
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom'
import ClientsPage from './clientspage'
import OffersPage from './offersPage'
import BannersPage from './imagePage'
import Restaurantspage from './restaurantspage'
import AuthenticationService from '../services/AuthenticationService';
import ChargeToRestaurantspage from './chargeToRestaurantspage'
import DropDowComponent from '../components/DropDowComponent'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TextBoxComponent from '../components/TextboxComponent'
import ProfilesService from '../services/app/ProfilesService'
import ReportContentpage from './reportContentpage'
import PublicidadPage from './publicidadpage.jsx'
import NewPublicidad from './Publicidad/NewPublicidad'
import PublicidadDetails from './Publicidad/PublicidadDetails'

const HomePage = () => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isTabletOrMobile  = useMediaQuery({ query: '(max-width: 1224px)' })

    const [password,setPassword] = useState('');
    const [newPassword,setNewPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const [passwordValidation,setPasswordValidation] = useState('');
    const [newPasswordValidation,setNewPasswordValidation] = useState('');
    const [confirmPasswordValidation,setConfirmPasswordValidation] = useState('');

    function handlePasswordChange(e) {
        setPassword(e.target.value)
        if(e.target.value == "" || e.target.value.includes('  ')){
            setPasswordValidation({hasValidate:true,hasError:true,error:'La contraseña es requerida'});
        }
        else if(e.target.value.length > 16)
        {
            setPasswordValidation({hasValidate:true,hasError:true,error:'Maximo 16 caracteres'});
        }
        else{
            setPasswordValidation({hasValidate:true,hasError:false})
        }
    }

    function handleNewPasswordChange(e) {
        setNewPassword(e.target.value)
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if(e.target.value == "" ){
            setNewPasswordValidation({hasValidate:true,hasError:true,error:'La nueva contraseña es requerida'})
        }
        else if(!passwordRegex.test(String(e.target.value).toLowerCase())){
            setNewPasswordValidation({hasValidate:true,hasError:true,error:'El formato no es correcto.'});
            if(confirmPassword !== "" && confirmPassword !== e.target.value){
                setConfirmPasswordValidation({hasValidate:true,hasError:true,error:'Las contraseñas no coinciden'})
            }
        }
        else if(e.target.value.length > 16)
        {
            setNewPasswordValidation({hasValidate:true,hasError:true,error:'Maximo 16 caracteres'});
        }
        else{
            setNewPasswordValidation({hasValidate:true,hasError:false})
            if(confirmPassword !== "" && confirmPassword === e.target.value){
                setConfirmPasswordValidation({hasValidate:true,hasError:false})
            }
        }
    }

    function handleConfirmPasswordChange(e) {
        setConfirmPassword(e.target.value)
        if(newPassword != e.target.value){
            setConfirmPasswordValidation({hasValidate:true,hasError:true,error:'Las contraseñas no coinciden'})
        }
        else if(e.target.value.length > 16)
        {
            setConfirmPasswordValidation({hasValidate:true,hasError:true,error:'Maximo 16 caracteres'});
        }
        else if(e.target.value == "" ){
            setConfirmPasswordValidation({hasValidate:true,hasError:true,error:'La nueva contraseña es requerida'})
        }
        else{
            setConfirmPasswordValidation({hasValidate:true,hasError:false})
        }
        
    }

    function notify(message) {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      function error(message) {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    function clearPassword() {
        setPasswordValidation({});
        setPassword('');
        setNewPasswordValidation({});
        setNewPassword('');
        setConfirmPasswordValidation({});
        setConfirmPassword('');
    }

    function putPassword() {
        let model = {};
        model.password = password;
        model.newPassword = newPassword;
        model.confirmPassword = confirmPassword;
        if(passwordValidation.hasValidate !== undefined && !passwordValidation.hasError && 
            newPasswordValidation.hasValidate !== undefined && !newPasswordValidation.hasError && 
            confirmPasswordValidation.hasValidate !== undefined && !confirmPasswordValidation.hasError){
            ProfilesService.putChangePassword(model).then((r)=>{
                if(r.success === 1){
                    notify(r.message)
                    clearPassword();
                    ModalFunctions.HideModal('modalPasswordReset');
                }
                else if(r.data !== null){
                    if(r.data.errors.password !== undefined){
                        error(r.data.errors.password[0])
                    }
                    if(r.data.errors.new_password !== undefined){
                        error(r.data.errors.new_password[0])
                    }
                    if(r.data.errors.new_password_confirmation !== undefined){
                        error(r.data.errors.new_password_confirmation[0])
                    }
                }
                else {
                    if(r.message === "La contraseña actual es incorrecta"){
                        setPasswordValidation({hasValidate:true,hasError:true,error: r.message})
                    }
                    else{
                        error(r.message)
                    }
                }

            });
        }
        else{
            if(passwordValidation.hasValidate === undefined){
                setPasswordValidation({hasValidate:true,hasError:true,error:'La contraseña es requerida'});
            }
            if(newPasswordValidation.hasValidate === undefined){
                setNewPasswordValidation({hasValidate:true,hasError:true,error:'La nueva contraseña es requerida'});
            }
            if(confirmPasswordValidation.hasValidate === undefined){
                setConfirmPasswordValidation({hasValidate:true,hasError:true,error:'La confirmación de la nueva contraseña es requerida'});
            }
        }
    }

    const DesktopVersion = (
        <div className="app-container">
            <header>
                <a style={{padding: "12px"}} onClick={()=> {ModalFunctions.ShowModal('mod_generalMenu')}}>
                    <img src="/images/menu.svg"/>
                </a>
                <span style={{width:"2px", height: "42px", background: "#000001", margin: "22px 10px", opacity: "0.1"}}></span>
                <a style={{padding: "12px"}}>
                    <img src="/images/puerta21_logotype_05@3x.png" width="130"/>
                </a>
                <span style={{flexGrow:"1"}}></span>
                <DropDowComponent idDrop={"userMenu"} textAlignment="right" image="iconUserBlack" iconSize="36px" style={{ marginRight:"4px", left:"-24px", top:"55px", textAlign: "right" }}
                    options={
                    [{
                        onClick: () => {
                            ModalFunctions.HideModal('mod_generalMenu');
                            ModalFunctions.ShowModal('modalPasswordReset')
                        },
                        page: "#",
                        text: "EDITAR CONTRASEÑA"
                    },
                    {
                        onClick: () => {
                            ModalFunctions.HideModal('mod_generalMenu');
                            ModalFunctions.ShowModal('modalCloseSession')
                        },
                        page: "#",
                        text: "CERRAR SESIÓN"
                    }]
                }/>
            </header>
            <Router>
                <Switch> 
                    <Route path="/Clients" component={ClientsPage}/>
                    <Route path="/Offers" component={OffersPage} />
                    <Route path="/Banners" component={BannersPage} />
                    <Route path="/Restaurants" component={Restaurantspage}/>
                    <Route path="/ChargeTo" component={ChargeToRestaurantspage}/>
                    <Route path="/ReportContent" component={ReportContentpage}/>
                    <Route path="/publicidad/new" component={() => <NewPublicidad />} />
                    <Route path="/publicidad/:id" component={() => <PublicidadDetails />} />
                    <Route path="/publicidad" component={() => <PublicidadPage />} />
                    <Route path="/" component={ClientsPage} />
                </Switch>
            </Router>
            <ModalComponent id="mod_generalMenu">
                <header>
                    <a style={{padding: "12px"}} onClick={()=> {ModalFunctions.HideModal('mod_generalMenu')}}>
                        <img src="/images/closeIcon.svg"/>
                    </a>
                    <span style={{width:"2px", height: "42px", background: "#ffffff", margin: "22px 10px", opacity: "0.25"}}></span>
                    <a style={{padding: "12px"}}>
                        <img src="/images/home-page-nav-logo-black.png"/>
                    </a>
                </header>
                <article style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}>
                    <div>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/Clients' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            SOLICITUDES Y CLIENTES
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/Restaurants' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            RESTAURANTES
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/Offers' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            OFERTAS
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/Banners' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            BANNERS
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/ChargeTo' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            COBRO A RESTAURANTES
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/publicidad' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            PUBLICIDAD
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/ReportContent' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            CONTENIDO REPORTADO
                        </a>
                        <a className="regularLinkWhite" style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }} onClick={ () => {
                            ModalFunctions.HideModal('mod_generalMenu');
                            ModalFunctions.ShowModal('modalCloseSession') }}>
                            CERRAR SESIÓN
                        </a>
                    </div>
                </article>
            </ModalComponent>
            <ModalComponent id="modalCloseSession" semi="1">
                <article style={{padding:"23px"}}>
                    <h4 style={{fontFamily:"Commuters Sans SemiBold", width: "300px", marginRight: "auto", textTransform:"uppercase",textAlign:"left", fontSize:"20px"}}>
                            ¿Deseas cerrar tú sesión en Puerta 21?
                    </h4>
                    <div style={{display:"flex",marginLeft: "auto",marginTop:"115px"}}>
                        <a className="btn-link" type="button" onClick={()=>{ModalFunctions.HideModal('modalCloseSession')}} style={{color:"black", margin: "15px"}}>Mantener</a>
                        <button className="btn btn-primary" type="button" onClick={()=>AuthenticationService.logout()}>Cerrar sesión</button>
                    </div>
                </article>
            </ModalComponent>
            <ModalComponent id="modalPasswordReset" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                flexGrow: "1",
                                color: "black",
                                marginBottom:'45px'}}>
                                CONTRASEÑA
                            </label>
                            <TextBoxComponent type="text" placeholder="Contraseña actual" style={{marginBottom:"20px"}} value={password} {...passwordValidation} onChange={handlePasswordChange}/>
                            <TextBoxComponent type="text" placeholder="Nueva contraseña" style={{marginBottom:"20px"}} value={newPassword} {...newPasswordValidation} onChange={handleNewPasswordChange}/> 
                            <TextBoxComponent type="text" placeholder="Confirma la nueva contraseña" value={confirmPassword} {...confirmPasswordValidation} onChange={handleConfirmPasswordChange}/> 
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <a className="btn-link" type="button" onClick={()=>{
                                setPasswordValidation({});
                                setPassword('');
                                setNewPasswordValidation({});
                                setNewPassword('');
                                setConfirmPasswordValidation({});
                                setConfirmPassword('');
                                ModalFunctions.HideModal('modalPasswordReset');
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</a>
                        <button className="btn btn-primary" type="button" onClick={() => {putPassword()}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
            <ToastContainer
                    position="top-right"
                    autoClose={1500}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{top:'6em', width:'400px'}}
                />
        </div>
    )

    const MobileVersion = (
        <div className="app-container">
            <header>
                {/* {
                    parentLocation != '' &&
                    <a style={{padding: "12px", margin: "12px", position: "relative" }} onClick={()=> window.location = parentLocation }>
                        <img width="24" height="24" src="/images/backicon.svg"/>
                    </a>
                }
                <h5 style={{ margin: parentLocation != '' ? "4px 72px 0 0" : "4px 10px 0 0", "text-transform" : "uppercase",fontFamily:'Commuters Sans SemiBold', fontSize: '16px', fontWeight: '600', flexGrow: "1", padding:"0px 0px 0px 50px"}}>
                    {
                        pageTitle
                    }
                </h5>
                {
                    addPage != '' &&
                    <a style={{padding: "12px", margin: "12px", position: "relative" }} onClick={()=> window.location = addPage}>
                        <img width="40" height="40" src="/images/plus-circle-fill.svg"/>
                    </a>
                } */}
            </header>
            <Router>
                <Switch>
                    <Route path="/Clients" component={() => <ClientsPage/>}/>
                    <Route path="/Restaurants" component={() => <Restaurantspage/>}/>
                    <Route path="/ChargeTo" component={() => <ChargeToRestaurantspage/>}/>
                    <Route path="/ReportContent" component={() => <ReportContentpage/>}/>
                    <Route path="/publicidad/new" component={() => <NewPublicidad />} />
                    <Route path="/publicidad/:id" component={() => <PublicidadDetails />} />
                    <Route path="/publicidad" component={() => <PublicidadPage />} />
                    <Route path="/" component={() => <ClientsPage/>} />
                    <Route path="/Offers" component={() => <OffersPage/>} />


                </Switch>
            </Router>
            <footer onClick={()=> {ModalFunctions.ToggleModal('mod_generalMenu')}}>
                <a style={{padding: "12px", margin: "12px", position: "relative" }} >
                </a>
                <h5 style={{fontFamily:'Commuters Sans SemiBold', fontSize: '16px', margin: "0", color:"white"}}>
                    MENU
                </h5>
                <div className="dropdown" onClick={(e)=> {
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <DropDowComponent idDrop={"userMenu"} textAlignment="right" image="iconUser" style={{ marginTop: "-2px", left:"3px", top:"-52px", textAlign: "right" }} options={
                        [{
                            onClick: () => {
                                ModalFunctions.HideModal('mod_generalMenu');
                                ModalFunctions.ShowModal('modalPasswordReset')
                            },
                            page: "#",
                            text: "EDITAR CONTRASEÑA"
                        },
                        {
                            onClick: () => {
                                ModalFunctions.HideModal('mod_generalMenu');
                                ModalFunctions.ShowModal('modalCloseSession')
                            },
                            page: "#",
                            text: "CERRAR SESIÓN"
                        }]
                    }/>
                </div>
            </footer>
            <ModalComponent id="mod_generalMenu">
                <article style={{height: "100%"}} onClick={ () => { ModalFunctions.HideModal('mod_generalMenu'); }}>
                    <div>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/Clients' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            CLIENTES
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/Restaurants' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            RESTAURANTES
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/Offers' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            OFERTAS
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/ChargeTo' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            COBROS
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/publicidad' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            PUBLICIDAD
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/ReportContent' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            CONTENIDO
                        </a>
                        <a className="regularLinkWhite" onClick={ () => { document.location = '/Banners' }} style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }}>
                            BANNERS
                        </a>
                        <a className="regularLinkWhite" style={{
                            paddingTop:"20px",
                            paddingBottom: "20px",
                            fontSize:'18px'
                        }} onClick={ () => {
                            ModalFunctions.HideModal('mod_generalMenu');
                            ModalFunctions.ShowModal('modalCloseSession') }}>
                            CERRAR SESIÓN
                        </a>
                    </div>
                </article>
            </ModalComponent>
            <ModalComponent id="modalCloseSession" semi="1">
                <article style={{padding:"23px", overflow: "auto"}}>
                    <h4 style={{fontFamily:"Commuters Sans SemiBold", width: "300px", marginRight: "auto", textTransform:"uppercase",textAlign:"left", fontSize:"20px"}}>
                            ¿Deseas cerrar tú sesión en Puerta 21?
                    </h4>
                    <div style={{marginTop:"115px", width:"100%",textAlign:"right"}}>
                        <a className="btn-link" type="button" onClick={()=>{ModalFunctions.HideModal('modalCloseSession')}} style={{color:"black", padding: "15px", display: "inline-block", width:"45%", maxWidth:"200px",textAlign:"center"}}>Mantener</a>
                        <button className="btn btn-primary" type="button" style={{display: "inline-block",width:"55%", paddingLeft:"0", paddingRight:"0", maxWidth:"200px"}} onClick={()=>AuthenticationService.logout()}>Cerrar sesión</button>
                    </div>
                </article>
            </ModalComponent>
            <ModalComponent id="modalPasswordReset" semi="1">
                    <article style={{padding:"23px"}}>
                        <div style={{display: "flex", alignItems: "flex-start", flexDirection:'column', padding: "45px 40px", width:'100%'}}>
                            <label style={{
                                width: "111px",
                                height: "25px",
                                fontFamily: "Commuters Sans SemiBold",
                                fontSize: "20px",
                                fontWeight: 600,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: 1.25,
                                letterSpacing: "normal",
                                textAlign: "left",
                                flexGrow: "1",
                                color: "black",
                                marginBottom:'45px'}}>
                                CONTRASEÑA
                            </label>
                            <TextBoxComponent type="text" placeholder="Contraseña actual" style={{marginBottom:"20px"}} value={password} {...passwordValidation} onChange={handlePasswordChange}/>
                            <TextBoxComponent type="text" placeholder="Nueva contraseña" style={{marginBottom:"20px"}} value={newPassword} {...newPasswordValidation} onChange={handleNewPasswordChange}/> 
                            <TextBoxComponent type="text" placeholder="Confirma la nueva contraseña" value={confirmPassword} {...confirmPasswordValidation} onChange={handleConfirmPasswordChange}/> 
                        </div>
                        <div style={{display:"flex",marginTop:"115px"}}>
                        <a className="btn-link" type="button" onClick={()=>{
                                clearPassword()
                                ModalFunctions.HideModal('modalPasswordReset');
                                }} style={{color:"black", margin: "15px"}}>CANCELAR</a>
                        <button className="btn btn-primary" type="button" onClick={() => {putPassword()}}>GUARDAR</button>
                        </div>
                    </article>
            </ModalComponent>
        </div>
    )

    return isDesktopOrLaptop ? DesktopVersion : MobileVersion;
}

export default HomePage
