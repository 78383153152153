import React, { useEffect, useState } from 'react';
import '../scss/offers.scss';
import ModalComponent, { ModalFunctions } from '../components/ModalComponent2.jsx';
import DropDowComponent from '../components/DropDowComponent.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const SwitchComponent = ({ initialState = false, onStateChange }) => {
  const [isActive, setIsActive] = useState(initialState);

  const handleStateChange = () => {
    const newState = !isActive;
    setIsActive(newState);
    onStateChange(newState);
  };

  return (
    <div className="form-check form-switch">
      <label className={`switch ${isActive ? 'active' : 'inactive'}`}>
        <input className="form-check-input" type="checkbox" checked={isActive} onChange={handleStateChange} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

const PublicidadPage = () => {
  const [offers, setOffers] = useState([]);
  const [restaurants, setRestaurants] = useState({});
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [refreshForce, setRefreshForce] = React.useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalOption, setShowModalOption] = useState(false);

  const loadRestaurants = async () => {
    try {
      const response = await fetch('https://api21.com.do/api/v1/listRestaurants');
      const result = await response.json();
      if (result && result.data) {
        // Crear un objeto con id como clave y nombre como valor
        const restaurantMap = {};
        result.data.forEach(restaurant => {
          restaurantMap[restaurant.id] = restaurant.name;
        });
        setRestaurants(restaurantMap);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al cargar los restaurantes');
    }
  };

  const loadOffers = async () => {
    try {
      const response = await fetch('https://api21.com.do/api/v1/listpublicArtisng');
      const data = await response.json();
      if (data) {
        setOffers(data);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error al cargar las publicaciones');
    }
  };

  useEffect(() => {
    loadOffers();
    loadRestaurants();
  }, []);

  const handleDelete = (offer, e) => {
    e.stopPropagation();
    if (window.confirm('¿Estás seguro de que deseas eliminar esta publicidad?')) {
      fetch(`https://api21.com.do/api/v1/publicArtisng/delete/${offer.id}`, {
        method: 'PUT'
      })
      .then(response => response.json())
      .then((r) => {
        if(r != null && r.success === 1) {
          toast.success('Publicidad eliminada exitosamente');
          loadOffers(); // Recargar la lista
        } else {
          toast.error(r.message || 'Error al eliminar la publicidad');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('Error al eliminar la publicidad');
      });
    }
  };

  const handleSwitchChange = (offer, newState) => {
    console.log(`Publicidad "${offer.id}" is now ${newState ? '1' : '0'}`); 
    const body = {};
    body.activo = newState;
    
    fetch(`https://api21.com.do/api/v1/publicArtisng/status/${offer.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then((r) => {
      if(r != null && r.success === 1) {
        notify(r.message)
        setTimeout(() => {
          document.location = '/publicidad';
        }, 1500);
      } else {
        console.log(r.message)
      }
    });
  };

  function notify(message) {
    toast.success(message || 'Información actualizada con éxito', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored"
    });
  }

  const openModal = (offer) => {
    setSelectedOffer(offer);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedOffer(null);
    setModalIsOpen(false);
  };

  const handleShowModal = (offer, e) => {
    e.stopPropagation();
    setSelectedOffer(offer);
    setShowModalOption(true);
    setModalIsOpen(true);
  };

  const handleCloseModalOption = () => {
    setSelectedOffer(null);
    setShowModalOption(false);
  };

  const handleCloseModal = () => {
    setSelectedOffer(null);
    setShowModal(false);
  };

  return (
    <div className="offers-page">
      <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
        <label style={{
          width: "111px",
          height: "25px",
          fontFamily: "Commuters Sans SemiBold",
          fontSize: "20px",
          fontWeight: 600,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.25,
          letterSpacing: "normal",
          textAlign: "left",
          flexGrow: "1",
          color: "black"
        }}>
          PUBLICIDAD
        </label>
        <Link to="/publicidad/new" style={{
          width:"284px",
          height: "40px"
        }} className="btn btn-primary" id="btnInvitation">Crear Publicidad</Link>
      </div>
     
      <div style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', padding: '0 20px' }}>
        <table className="offers-table">
          <thead style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
            <tr>
              <th>Título</th>
              <th>Descripción</th>
              <th>Estado: Activo/Inactivo</th>
              <th>Posición</th>
              <th>Restaurante</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {offers.map(offer => (
              <tr key={offer.id}>
                <td>{offer.titulo}</td>
                <td>{offer.descripcion}</td>
                <td onClick={(e) => { e.stopPropagation(); }}>
                  <SwitchComponent
                    initialState={offer.activo === 1}
                    onStateChange={(newState) => handleSwitchChange(offer, newState)}
                    offer={offer}
                  />
                </td>
                <td>{offer.posicion}</td>
                <td>{restaurants[offer.restaurant_id] || 'No asignado'}</td>
                <td>
                  <Link to={`/publicidad/${offer.id}`} className="btn btn-primary" style={{ marginRight: '10px' }}>
                    Ver detalles
                  </Link>
                  <button 
                    onClick={(e) => handleDelete(offer, e)} 
                    className="btn btn-danger"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PublicidadPage;
