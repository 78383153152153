import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import RestaurantsService from '../../services/founder/RestaurantsServices.jsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TextField } from '@material-ui/core';

const PublicidadDetails = () => {
    const { id } = useParams();
    const [restaurantInfo, setRestaurantInfo] = useState({
        title: '',
        description: '',
        posiciones: [],
        imagen: '',
        enlace: '',
        restaurant_ids: [] // Asegurarnos de que existe este campo
    });
    const [restaurants, setRestaurants] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (id) {
            loadBanner();
            loadRestaurants();
        }
    }, [id]);

    const loadRestaurants = async () => {
        try {
            const response = await fetch('https://api21.com.do/api/v1/listRestaurants');
            const result = await response.json();
            if (result && result.data) {
                setRestaurants(result.data);
            }
        } catch (error) {
            console.error('Error al cargar los restaurantes:', error);
            toast.error('Error al cargar los restaurantes');
        }
    };

    const loadBanner = async () => {
        try {
            const response = await fetch('https://api21.com.do/api/v1/listpublicArtisng');
            const data = await response.json();
            
            if (data && Array.isArray(data)) {
                const publicidadFound = data.find(item => item.id === parseInt(id));
                
                if (publicidadFound) {
                    console.log('Datos cargados:', publicidadFound);
                    setRestaurantInfo({
                        title: publicidadFound.titulo || '',
                        description: publicidadFound.descripcion || '',
                        posiciones: publicidadFound.posicion_string ? [publicidadFound.posicion_string] : [],
                        imagen: publicidadFound.imagen || '', // Guardamos la URL de la imagen existente
                        enlace: publicidadFound.enlace || '',
                        restaurant_ids: publicidadFound.restaurant_id ? [publicidadFound.restaurant_id] : []
                    });
                } else {
                    toast.error('No se encontró la publicidad especificada');
                }
            }
        } catch (error) {
            console.error('Error al cargar la publicidad:', error);
            toast.error('Error al cargar la publicidad');
        }
    };

    const positionOptions = [
        'en_espera',
        'confirmacion',
        'recordatorio',
        'satisfaccion'
    ];

    const validateForm = () => {
        const errors = [];
        
        if (!restaurantInfo.title?.trim()) {
            errors.push('El título es requerido');
        }
        
        if (!restaurantInfo.description?.trim()) {
            errors.push('La descripción es requerida');
        }
        
        if (!restaurantInfo.enlace?.trim()) {
            errors.push('El enlace es requerido');
        }
        
        if (!restaurantInfo.posiciones?.length) {
            errors.push('Debe seleccionar al menos una posición');
        }

        if (!restaurantInfo.restaurant_ids?.length) {
            errors.push('Debe seleccionar al menos un restaurante');
        }

        // Validar imagen si existe
        if (restaurantInfo.imagen instanceof File) {
            if (restaurantInfo.imagen.size > 2 * 1024 * 1024) {
                errors.push('La imagen no debe exceder 2MB');
            }
            
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (!allowedTypes.includes(restaurantInfo.imagen.type)) {
                errors.push('El archivo debe ser una imagen (JPEG, PNG o GIF)');
            }
        }

        if (errors.length > 0) {
            errors.forEach(error => toast.error(error));
            return false;
        }
        
        return true;
    };

    const handleSave = async () => {
        if (!validateForm()) return;

        try {
            const formData = new FormData();
            
            // Campos básicos requeridos
            formData.append('title', restaurantInfo.title);
            formData.append('description', restaurantInfo.description);
            
            // Enviar posiciones como array
            restaurantInfo.posiciones.forEach((posicion, index) => {
                formData.append(`posiciones[${index}]`, posicion);
            });
            
            formData.append('enlace', restaurantInfo.enlace);
            
            // Enviar restaurant_ids como array
            restaurantInfo.restaurant_ids.forEach((restaurantId, index) => {
                formData.append(`restaurant_ids[${index}]`, restaurantId);
            });
            
            // ID del banner actual para eliminarlo
            formData.append('delete_id', id);

            // Imagen: si hay una nueva imagen, enviarla. Si no, enviar la URL de la imagen existente
            if (restaurantInfo.imagen instanceof File) {
                if (restaurantInfo.imagen.size > 2 * 1024 * 1024) {
                    toast.error('La imagen no debe exceder 2MB');
                    return;
                }
                formData.append('imagen', restaurantInfo.imagen);
            } else if (restaurantInfo.imagen) {
                // Si hay una imagen existente (URL), la enviamos como string
                formData.append('imagen_url', restaurantInfo.imagen);
            }

            // Debug para verificar los datos
            console.log('Datos a enviar:', {
                title: restaurantInfo.title,
                description: restaurantInfo.description,
                posiciones: restaurantInfo.posiciones,
                enlace: restaurantInfo.enlace,
                restaurant_ids: restaurantInfo.restaurant_ids,
                delete_id: id,
                imagen: restaurantInfo.imagen instanceof File ? 'Nueva imagen' : 
                       restaurantInfo.imagen ? 'Imagen existente: ' + restaurantInfo.imagen : 'Sin imagen'
            });

            const response = await fetch(`https://api21.com.do/api/v1/publicArtisng/edit/${id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body: formData
            });

            const result = await response.json();
            console.log('Respuesta del servidor:', result);

            if (result.success === 1) {
                toast.success('Publicidad actualizada exitosamente');
                setTimeout(() => {
                    document.location = '/publicidad';
                }, 1500);
            } else {
                toast.error(result.message || 'Error al actualizar la publicidad');
            }
        } catch (error) {
            console.error('Error al guardar:', error);
            toast.error('Error al guardar los cambios');
        }
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                toast.error('La imagen no debe exceder 2MB');
                return;
            }
            setRestaurantInfo({
                ...restaurantInfo,
                imagen: file
            });
        }
    };

    return (
        <article>
            <div style={{ display: "flex", alignItems: "center", padding: "29px 40px" }}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"
                }}>
                    EDITAR PUBLICIDAD
                </label>
            </div>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"
            }}></div>
            <div style={{ display: "flex", padding: "29px 36px 29px 40px", height: 'calc(100% - 150px)', overflow: 'auto' }}>
                <div style={{ display: "flex", width: '30%', justifyContent: 'space-between' }}>
                    <div style={{ textAlign: "left" }}>
                        <p style={{
                            fontFamily: 'Roboto',
                            fontSize: '14px',
                            color: 'black',
                            letterSpacing: '0.5px',
                            width: '80%',
                            paddingTop: '40px'
                        }}>
                            Editar los datos de la publicidad.
                        </p>
                    </div>
                    <div style={{
                        width: "0px",
                        height: "630px",
                        margin: "56px 0px 70px",
                        opacity: "0.15",
                        border: "solid 0.5px #000001"
                    }}></div>
                </div>
                <div style={{ display: 'flex', width: '70%' }}>
                    <form style={{
                        textAlign: "left",
                        paddingLeft: "40px",
                        width: '100%'
                    }}>
                        <div className="w-100">
                            <div className="RestaurantOffer-form-container mr-2">
                                <div style={{ color: 'black', fontFamily: 'roboto', fontSize: '16px' }}>
                                    <span>
                                        <b>Detalles de la publicidad</b>
                                        <br />
                                    </span>
                                </div>
                                <div className="RestaurantOffer__form">
                                    <div className="w100 mb-1">
                                        <TextField
                                            className="w100"
                                            id="title"
                                            label="Título"
                                            value={restaurantInfo.title}
                                            onChange={(e) => setRestaurantInfo({...restaurantInfo, title: e.target.value})}
                                        />
                                    </div>
                                    <div className="w100 mb-1">
                                        <TextField
                                            className="w100"
                                            id="description"
                                            label="Descripción"
                                            value={restaurantInfo.description}
                                            onChange={(e) => setRestaurantInfo({...restaurantInfo, description: e.target.value})}
                                        />
                                    </div>
                                    <div className="w100 mb-1">
                                        <label className="form-label">Restaurantes</label>
                                        <div style={{ 
                                            maxHeight: '150px', 
                                            overflowY: 'auto', 
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px',
                                            padding: '10px'
                                        }}>
                                            {/* Barra de búsqueda para restaurantes */}
                                            <TextField
                                                className="w100 mb-2"
                                                placeholder="Buscar restaurantes..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            
                                            {/* Lista de restaurantes filtrada */}
                                            {restaurants
                                                .filter(restaurant => 
                                                    restaurant.name.toLowerCase().includes(searchTerm.toLowerCase())
                                                )
                                                .map((restaurant) => (
                                                    <div key={restaurant.id} className="form-check mb-2">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id={`restaurant-${restaurant.id}`}
                                                            checked={restaurantInfo.restaurant_ids.includes(restaurant.id)}
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                setRestaurantInfo(prev => ({
                                                                    ...prev,
                                                                    restaurant_ids: isChecked
                                                                        ? [...prev.restaurant_ids, restaurant.id]
                                                                        : prev.restaurant_ids.filter(id => id !== restaurant.id)
                                                                }));
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor={`restaurant-${restaurant.id}`}>
                                                            {restaurant.name}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="w100 mb-1">
                                        <label className="form-label">Posiciones</label>
                                        <div style={{ 
                                            maxHeight: '150px', 
                                            overflowY: 'auto', 
                                            border: '1px solid #ced4da',
                                            borderRadius: '4px',
                                            padding: '10px'
                                        }}>
                                            {positionOptions.map((position) => (
                                                <div key={position} className="form-check mb-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`position-${position}`}
                                                        checked={restaurantInfo.posiciones.includes(position)}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setRestaurantInfo(prev => ({
                                                                ...prev,
                                                                posiciones: isChecked
                                                                    ? [...prev.posiciones, position]
                                                                    : prev.posiciones.filter(pos => pos !== position)
                                                            }));
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`position-${position}`}>
                                                        {position}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="w100 mb-1">
                                        <label className="form-label">Imagen</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            accept="image/*"
                                            onChange={handleImageUpload}
                                        />
                                        {restaurantInfo.imagen && !restaurantInfo.imagen instanceof File && (
                                            <img 
                                                src={restaurantInfo.imagen} 
                                                alt="Imagen actual" 
                                                style={{ maxWidth: '200px', marginTop: '10px' }} 
                                            />
                                        )}
                                    </div>
                                    <div className="w100 mb-1">
                                        <TextField
                                            className="w100"
                                            id="enlace"
                                            label="Enlace"
                                            value={restaurantInfo.enlace}
                                            onChange={(e) => setRestaurantInfo({...restaurantInfo, enlace: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: '20px', display: 'flex', gap: '10px', justifyContent: 'flex-start' }}>
                            <Link 
                                to="/publicidad" 
                                className="btn btn-lg" 
                                style={{ 
                                    width: '162px', 
                                    boxShadow: 'none', 
                                    fontFamily: 'Commuters Sans SemiBold',
                                    backgroundColor: '#f5f5f5',
                                    border: '1px solid #ddd',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                CANCELAR
                            </Link>
                            <button 
                                className="btn btn-primary btn-lg" 
                                type="button" 
                                style={{ 
                                    width: '162px',
                                    fontFamily: 'Commuters Sans SemiBold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '10px 20px',
                                    height: '48px'
                                }} 
                                onClick={handleSave}
                            >
                                ACTUALIZAR
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    );
};

export default PublicidadDetails;
