import React from 'react'
import { Link } from 'react-router-dom'
import CheckBoxComponent from '../components/CheckBoxComponent'
import { useMediaQuery } from 'react-responsive'
import PriceComponent from '../components/PriceComponent'
import TextBoxComponent from '../components/TextboxComponent'
import CatalogsService from '../services/app/CatalogsService'
import AuthenticationService from '../services/AuthenticationService';
import RestaurantsService from '../services/founder/RestaurantsServices'
import Select from 'react-select';

import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
  } from 'react-places-autocomplete';
import { toast } from 'react-toastify'
import { Rating } from 'react-simple-star-rating'
import IsSpecialCharacters from '../services/UltiService'

const NewRestaurant = () => {
    const jsToken = JSON.parse(AuthenticationService.curentUserValue);
    const data = jsToken.accessToken;

    const [restaurantNameValidation,setRestaurantNameValidation]= React.useState({});
    const [ownerNameValidation,setOwnerNameValidation]= React.useState({});
    const [emailValidation,setEmailValidation]= React.useState({});
    const [addresValidation,setAddresValidation]= React.useState(true);
    const [directionValidation,setDirectionValidation]= React.useState(true);
    const [zoneValidation,setZoneValidation]= React.useState(true);
    const [kitchensValidation,setKitchensValidation]= React.useState(true);
    const [token, setToken] = React.useState(data);

    const [restaurantName,setRestaurantName]= React.useState("");
    const [ownerName,setOwnerName]= React.useState("");
    const [email,setEmail]= React.useState("");
    const [direction,setDirection]= React.useState("");
    const [zone,setZone]= React.useState("");
    const [kitchens,setKitchens]= React.useState([]);
    const [cost,setCost]= React.useState("");
    const [extraServices,setExtraServices]= React.useState([]);
    const [latitude,setLatitude]= React.useState(0);
    const [longitude,setLongitude]= React.useState(0);
    const [costsCatalog,setCostsCatalog] = React.useState([]);
    const [extraServicesCatalog,setExtraServicesCatalog] = React.useState([]);
    const [zoneCatalog,setZoneCatalog] = React.useState([]);
    const [kitchenCatalog,setKitchenCatalog] = React.useState([]);
    const [kitchenComponent,setKitchenComponenet] = React.useState([]);

    const [address, setAddress] = React.useState('');
    const [rating,setRating] = React.useState(0);    

    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;


    function handleRestaurantNameChange(e){
        setRestaurantName(e.target.value);
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharacters(e.target.value)){
            setRestaurantNameValidation({hasValidate:true,hasError:true,error: e.target.value == "" ? 'Nombre del restaurante es requerido' : 'El formato no es correcto.'});
        }
        else if(e.target.value.length > 30)
        {
            setRestaurantNameValidation({hasValidate:true,hasError:true,error:'Maximo 30 caracteres'});
        }
        else if (regex.test(e.target.value)) {
            setRestaurantNameValidation({hasValidate:true,hasError:true,error:'Formato incorrecto.'});
        }
        else{
            setRestaurantNameValidation({hasValidate:true,hasError:false});
        }
    }

    function handleOwnerNameChange(e){
        setOwnerName(e.target.value);
        if(e.target.value == "" || e.target.value.includes('  ') || IsSpecialCharacters(e.target.value)){
            setOwnerNameValidation({hasValidate:true,hasError:true,error: e.target.value == "" ? 'Nombre del dueño es requerido' : 'El formato no es correcto.'});
        }
        else if(e.target.value.length > 30)
        {
            setOwnerNameValidation({hasValidate:true,hasError:true,error:'Maximo 30 caracteres'});
        }
        else if (regex.test(e.target.value)) {
            setOwnerNameValidation({hasValidate:true,hasError:true,error:'Formato incorrecto.'});
        }
        else{
            setOwnerNameValidation({hasValidate:true,hasError:false});
            
        }
    }

    function handleEmailChange(e){
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(!emailRegex.test(String(e.target.value).toLowerCase()))
        {
            setEmailValidation({hasValidate:true,hasError:true,error: e.target.value == "" ? 'Correo electronico es requerido' : 'El formato no es correcto.'})
            setEmail(e.target.value);
            return;
        }
        else if(e.target.value.length > 80)
        {
            setEmailValidation({hasValidate:true,hasError:true,error:'Maximo 80 caracteres'});
        }
        else if (regex.test(e.target.value)) {
            setEmailValidation({hasValidate:true,hasError:true,error:'Formato incorrecto.'});
        }
        else{
            setEmailValidation({hasValidate:true,hasError:false});
            setEmail(e.target.value);
        }
    }

    // function handlePasswordChange(e){
    //     const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    //     if(!passwordRegex.test(String(e.target.value).toLowerCase()))
    //     {
    //         setPasswordValidation({hasValidate:true,hasError:true,error:'La contraseña no tiene el formato correcto.'})
    //         setPassword(e.target.value);
    //         return;
    //     }
    //     else
    //     {
    //         setPasswordValidation({hasValidate:true,hasError:false});
    //         setPassword(e.target.value);
    //     }
    // }

    function handleDirectionChange(e){
        if(e.target.value == ""){
            setDirectionValidation({hasValidate:true,hasError:true,error:'Campo requerido'});
        }
        else{
            setDirectionValidation({hasValidate:true,hasError:false});
            // setDirectionInfo(e.target.value);
        }
    }

    // function setDirectionInfo(address){
    //     setLongitude(0);
    //     setLatitude(0);
    //     setDirection(address);
    // }

    const handleChange = address => {
        setAddress(address);
        setDirection(address);
        if(address == "")
        {
            setLatitude(0)
            setLongitude(0)
            setAddresValidation(false);
            setDirectionValidation(true);
        }
        if(latitude == 0 && longitude == 0){
            setDirectionValidation(false);
            setAddresValidation(true);
        }
      };
     
    const handleSelect = address => {
        setAddress(address);
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {
              setLatitude(latLng.lat);
              setLongitude(latLng.lng);
              setDirection(address);
              setDirectionValidation(true);
              setAddresValidation(true);
            })
          .catch(error => console.error('Error', error));
      };

    function setRatingComponent(rating) {
        setRating(rating);
        switch (rating)
        {
            case 1:
                let one = costsCatalog.find(x => x.name === "$");
                setCost(one.encrypt_id);
            break;
            case 2:
                let two = costsCatalog.find(x => x.name === "$$");
                setCost(two.encrypt_id);
            break;
            case 3:
                let three = costsCatalog.find(x => x.name === "$$$");
                setCost(three.encrypt_id);
            break;
            case 4:
                let four = costsCatalog.find(x => x.name === "$$$$");
                setCost(four.encrypt_id);
            break;
            case 5:
                let five = costsCatalog.find(x => x.name === "$$$$$");
                setCost(five.encrypt_id);
            break;
            default:
                setCost("")
        }
    };

    function handleValet(e){
        if(e.target.checked){
           let valet = extraServicesCatalog.find(x => x.name === "Valet parking");
           extraServices.push(valet.encrypt_id);
        }
        else{
            let valet = extraServicesCatalog.find(x => x.name === "Valet parking");
            let temp = extraServices.filter(x => x !== valet.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Menú vegano");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Menú vegano");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }
    function handleMenu2(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Miembro de Aderes: Asociación Dominicana de restaurantes");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Miembro de Aderes: Asociación Dominicana de restaurantes");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu3(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Certificado Ministerio de Turismo");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Certificado Ministerio de Turismo");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }


    function handleMenu4(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Admiten mascotas");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Admiten mascotas");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu5(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Terraza");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Terraza");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu6(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Área para fumar");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Área para fumar");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu7(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Parqueo cerca");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Parqueo cerca");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu8(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Salones privados");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Salones privados");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu9(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Área de juegos para niños");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Área de juegos para niños");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu10(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Cena el 24 de diciembre");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Cena el 24 de diciembre");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }


    function handleMenu11(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Cena el 31 de diciembre");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Cena el 31 de diciembre");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }


    function handleMenu12(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Posibilidad de reservar el restaurante completo para eventos");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Posibilidad de reservar el restaurante completo para eventos");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu13(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Desayuno");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Desayuno");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }
    function handleMenu14(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Cigar");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Cigar");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }

    function handleMenu15(e){
        if(e.target.checked){
            let menu = extraServicesCatalog.find(x => x.name === "Reserva Especial");
            extraServices.push(menu.encrypt_id);
        }
        else{
            let menu = extraServicesCatalog.find(x => x.name === "Reserva Especial");
            let temp = extraServices.filter(x => x !== menu.encrypt_id);
            setExtraServices(temp);
        }
    }
    
    



    function handleAccess(e){
        if(e.target.checked){
            let access = extraServicesCatalog.find(x => x.name === "Acceso para silla de ruedas");
            extraServices.push(access.encrypt_id);
        }
        else{
            let access = extraServicesCatalog.find(x => x.name === "Acceso para silla de ruedas");
            let temp = extraServices.filter(x => x !== access.encrypt_id);
            setExtraServices(temp);
        }
    }

    function addNewRestaurant(){

        if(!navigator.onLine)
        {
            error("No se detecta una red estable");
            return;
        }

        if(restaurantNameValidation.hasError === undefined)
        {
            setRestaurantNameValidation({hasValidate:true,hasError:true,error:'Campo requerido'});
        }

        if(ownerNameValidation.hasError === undefined)
        {
            setOwnerNameValidation({hasValidate:true,hasError:true,error:'Campo requerido'});
        }

        if(emailValidation.hasError === undefined)
        {
            setEmailValidation({hasValidate:true,hasError:true,error:'Campo requerido'});
        }

        if(!directionValidation)
        {
            setDirectionValidation(false);
        }

        if(!zoneValidation)
        {
            setZoneValidation(false);
        }

        if(!kitchensValidation)
        {
            setKitchensValidation(false);
        }

        if(!restaurantNameValidation.hasError && !ownerNameValidation.hasError &&
            !emailValidation.hasError &&
            direction !== "" && latitude !== 0 && longitude !== 0 && 
            zone !== "" && kitchenComponent.length >= 1)
        {
            kitchenComponent.map(data =>{
                kitchens.push(data.value)
            });
            let model = {};
            model.name = restaurantName;
            model.ownerName = ownerName;
            model.email = email;
            model.address = direction;
            model.latitude = latitude;
            model.longitude = longitude;
            model.zone = zone;
            model.kitchens = kitchens;
            model.cost = cost;
            model.extraServices = extraServices;
            RestaurantsService.postNewRestaurant(model).then((r) =>{
                if(r != null && r.message == "El restaurante fue creado correctamente"){
                    notify(r.message)
                    setTimeout(() => {
                        document.location = '/Restaurants';
                    }, 1500);
                }
                else if (r != null && r.message == "Datos invalidos"){
                    if (r.data.email != undefined && r.data.email[0] == "The email has already been taken."){
                        error('El correo ya existe')
                    }
                    if (r.data.extra_services != undefined && r.data.extra_services[0] == "The extra services field is required."){
                        error('Al menos un servicio extra es requerido')
                    }
                    if (r.data.cost_eid != undefined && r.data.cost_eid[0] == "The cost eid field is required."){
                        error('Precio es requerido')
                    }
                }

            });
        }
        else
        {
            if(direction === "")
            {
                setDirectionValidation(false);
            }
    
            if(zone === "")
            {
                setZoneValidation(false);
            }
    
            if(kitchenComponent.length === 0)
            {
                setKitchensValidation(false);
            }
        }
    }

    function notify(message) {
        toast.success(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

      function error(message) {
        toast.error(message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
        });
      }

    function getExtraCatalog(){
        CatalogsService.getExtraService(token).then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setExtraServicesCatalog(r);
                }
            }
        });
    }

    function getCostsCatalog(){
        CatalogsService.getCost(token).then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setCostsCatalog(r);
                }
            }
        });
    }

    function getZoneCatalog(){
        CatalogsService.getZone(token).then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    setZoneCatalog(r);
                }
            }
        });
    }

    function getKitchensCatalog(){
        CatalogsService.getKitchen(token).then((r) =>{
            if(r != null)
            {
                if(r.length != 0)
                {
                    let temp = [];
                    r.map(data =>{
                        let model = {};
                        model.value = data.encrypt_id;
                        model.label = data.name;
                        temp.push(model);
                    });
                    setKitchenCatalog(temp);
                }
            }
        });
    }

    function handleZone(e){
        if(e.target.value != "Zona"){
            setZone(e.target.value);
            setZoneValidation(true);
        }
        else{
            setZone("")
            setZoneValidation(false);
        }
    }

    function handlekitchen(e){
        if(e.length == 0){
            setKitchensValidation(false);
            setKitchenComponenet(e)
        }
        else{
            setKitchenComponenet(e)
            setKitchensValidation(true);
        }
        
    }

    // function getPassword() {
    //     let configuracion = {
    //         numeroDeCaracteres: 15,
    //         simbolos: true,
    //         numeros: true,
    //         mayusculas: true
    //     }
    //     const caracteres = {
    //         numeros: '0 1 2 3 4 5 6 7 8 9',
    //         simbolos: '@ $ ! % * # ? &',
    //         mayusculas: 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z',
    //         minusculas: 'a b c d e f g h i j k l m n o p q r s t u v w x y z'
    //     }
        
    //     let caracteresFinales = '';
    //     let password = '';
    
    //     for(let propiedad in configuracion){
    //         if(configuracion[propiedad] === true){
    //             caracteresFinales += caracteres[propiedad] + ' ';
    //         }
    //     }
    
    //     caracteresFinales += caracteres.minusculas;
    //     caracteresFinales = caracteresFinales.trim();
        
    //     caracteresFinales = caracteresFinales.split(' ');
    
    //     for(let i = 0; i < configuracion.numeroDeCaracteres; i++){
    //         password += caracteresFinales[Math.floor(Math.random() * caracteresFinales.length)];
    //     }
    
    //     let e = {target: {value: password}};
    //     handlePasswordChange(e) ;
    // }

    React.useEffect(()=>{
        getExtraCatalog();
        getCostsCatalog();
        getZoneCatalog();
        getKitchensCatalog();
    },[]);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })

    const desktop = () =>(
        <article>
            <div style={{display: "flex", alignItems: "center", padding: "29px 40px"}}>
                <label style={{
                    width: "111px",
                    height: "25px",
                    fontFamily: "Commuters Sans SemiBold",
                    fontSize: "20px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    "flex-grow": "1",
                    color: "black"}}>
                    NUEVO RESTAURANTE
                </label>
            </div>
            <div style={{
                    borderBottom: "2px solid #c8c8c8",
                    height: "0px",
                    textAlign: "left",
                    padding: "0 24px"}}>
            </div>
            <div style={{display: "flex", padding: "29px 36px 29px 40px", height:'calc(100% - 150px)', overflow:'auto'}}>
                <div style={{display:"flex", width:'40%', justifyContent:'space-between'}}>
                    <div style={{textAlign: "left"}}>
                        <p style={{
                                    fontFamily:'Roboto',
                                    fontSize:'14px',
                                    color:'black',
                                    letterSpacing:'0.5px',
                                    width:'80%',
                                    paddingTop:'40px'}}>
                            Completa los datos solicitados para agregar un restaurante.
                        </p>
                    </div>
                    <div style={{
                        width: "0px",
                        height: "630px",
                        margin: "56px 0px 70px",
                        opacity: "0.15",
                        border: "solid 0.5px #000001",
                        paddingLeft:"0px",
                        paddingRight:"0px"
                    }}>
                    </div>
                </div>
                <div style={{display:'flex', width:'60%'}}>
                    <form style={{
                        textAlign: "left",
                        paddingLeft: "40px",
                        width:'100%'
                    }}>
                        <div style={{paddingTop:'40px'}}>
                            <TextBoxComponent placeholder="Nombre de restaurante" type="text" value={restaurantName} {...restaurantNameValidation} onChange={handleRestaurantNameChange}/>
                        </div>                        
                        <div style={{paddingTop:'25px'}}>
                            <TextBoxComponent placeholder="Nombre de dueño" type="text" value={ownerName} {...ownerNameValidation} onChange={handleOwnerNameChange}/>
                        </div>
                        <div style={{paddingTop:'25px'}}>
                            <TextBoxComponent placeholder="Correo electrónico" type="text" value={email} {...emailValidation} onChange={handleEmailChange}/>
                        </div>
                        {/* <div style={{paddingTop:'25px'}}>
                            <TextBoxComponent placeholder="Contraseña" type="text" {...passwordValidation} onChange={handlePasswordChange} value={password}/>
                            <label className="puerta21-btn" style={{position:'relative', 
                                                                left: 'calc(100% - 90px)', 
                                                                bottom: 65, 
                                                                width:"80px", 
                                                                height:"24px", 
                                                                fontSize:"12px", 
                                                                textAlign:"center", 
                                                                paddingBottom:"0px",
                                                                paddingTop:"0px",
                                                                paddingLeft:"0px",
                                                                paddingRight:"0px", 
                                                                boxShadow:"none"}}
                                                                onClick={getPassword}>
                                GENERAR
                            </label>
                        </div> */}
                        <div style={{paddingTop:'25px'}}>
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                    style={{width:'100%'}}
                                    {...getInputProps({
                                        placeholder: 'Buscar direccion ...',
                                        className: 'puerta21-select col-12 d-block location-search-input',
                                    })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                    {loading && <div>Buscando...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </div>
                                )}
                            </PlacesAutocomplete>
                            <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={directionValidation}>Selecciona un elemento de la lista</label>
                            <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={addresValidation}>Campo requerido</label>
                            {/* <TextBoxComponent placeholder="Dirección" type="text" {...directionValidation} onChange={handleDirectionChange}/> */}
                        </div>
                        <div style={{paddingTop:'25px'}}>
                            <select className={"puerta21-select col-12 d-block"} style={{
                                border:"none",
                                borderBottom:"1px solid black",
                                padding:"16px 0",

                            }} onChange={(e) => {handleZone(e)}}>
                                <option selected>Zona</option>
                                {
                                    zoneCatalog.map(z =>(
                                        <option value={z.encrypt_id}>{z.name}</option>
                                    ))
                                }
                            </select>
                            <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={zoneValidation}>Campo requerido</label>
                        </div>
                        <div style={{paddingTop:'25px'}}>
                            <Select
                                onChange={handlekitchen}
                                placeholder="Tipo de comida"
                                isMulti
                                name="kitchen"
                                options={kitchenCatalog}
                                className="puerta21-select col-12 d-block basic-multi-select"
                                classNamePrefix="select"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      neutral30: 'white',
                                      neutral20: 'white',
                                      primary: 'white',
                                    },
                                })}
                            />
                            <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={kitchensValidation}>Al menos una comida es requerida</label>
                            {/* <select className={"puerta21-select col-12 d-block"} style={{
                                border:"none",
                                borderBottom:"1px solid black",
                                padding:"16px 0",

                            }} onChange={(e) => {handlekitchen(e)}}>
                                <option selected>Tipo de comida</option>
                                {
                                    kitchenCatalog.map(k =>(
                                        <option value={k.encrypt_id}>{k.name}</option>
                                    ))
                                }
                            </select> */}
                        </div>
                        <div style={{marginTop:'28px', display:'flex', flexDirection:'column'}}> 
                            <label>Rango de precios</label>
                            <div style={{marginLeft:'-8px'}}>
                                <Rating
                                    onClick={setRatingComponent}
                                    ratingValue={rating}
                                    transition
                                    size={30}
                                    stars={5}
                                    fillColor={'black'}
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                                    </svg>
                                </Rating>
                            </div>
                            {/* <PriceComponent style={{width:'150px', height:'35px'}} value={0} edit={true} size={30} onChange={setRatingComponent}/> */}
                        </div>
                        <div className="row" style={{paddingTop:'25px'}}> 
                            <div className="col-3">
                                <CheckBoxComponent style={{marginTop: "30px"}} text="Valet parking" onChange={handleValet}/>
                                <CheckBoxComponent text="Terraza" onChange={handleMenu5}/>
                                <CheckBoxComponent text="Parqueo cerca" onChange={handleMenu7}/>
                                <CheckBoxComponent text="Cena el 31 de diciembre" onChange={handleMenu11}/>
                                
                                
                            </div>
                            <div className="col-3">
                                <CheckBoxComponent style={{marginTop: "30px"}} text="Menú vegano" onChange={handleMenu}/>
                                <CheckBoxComponent text="Área para fumar" onChange={handleMenu6}/>
                                <CheckBoxComponent text="Salones privados" onChange={handleMenu8}/>
                                <CheckBoxComponent text="Área de juegos para niños" onChange={handleMenu9}/>



                            </div>
                            <div className="col-3">
                                <CheckBoxComponent style={{marginTop: "30px"}} text="Miembro de Aderes: Asociación Dominicana de restaurantes" onChange={handleMenu2}/>
                                <CheckBoxComponent text="Certificado Ministerio de Turismo" onChange={handleMenu3}/>
                                <CheckBoxComponent text="Cena el 24 de diciembre" onChange={handleMenu10}/>
                                <CheckBoxComponent text="Posibilidad de reservar el restaurante completo para eventos" onChange={handleMenu12}/>

                            </div>
                            <div className="col">
                                <CheckBoxComponent style={{marginTop: "30px"}} text="Acceso para silla de ruedas" onChange={handleAccess}/>
                                <CheckBoxComponent text="Admiten mascotas" onChange={handleMenu4}/>
                                <CheckBoxComponent text="Desayuno" onChange={handleMenu13}/>
                                <CheckBoxComponent text="Cigar" onChange={handleMenu14}/>
                                <CheckBoxComponent text="Reserva Especial" onChange={handleMenu15}/>


                            </div>
                        </div>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end" style={{paddingTop:'20px'}}>
                            <Link to="/" className="btn btn-lg mt-5" style={{width:'162px', boxShadow: 'none', fontFamily:'Commuters Sans SemiBold'}}>
                                CANCELAR
                            </Link>
                            <button className="btn btn-primary btn-lg mt-5" type="button" style={{width:'162px'}} onClick={() => {addNewRestaurant()}}>
                                AGREGAR
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    );
    
    const movile = () =>(
        <article style={{overflowX:'hidden'}}>
            <a style={{position: "absolute", top:25, left:'5%' }} onClick={()=> window.location = "/Restaurants" }>
                <img width="24" height="24" src="/images/backicon.svg"/>
            </a>
            <h5 style={{ 
                         "text-transform" : "uppercase",
                         fontFamily:'Commuters Sans SemiBold',
                         fontSize: '16px',
                         fontWeight: '600',
                         position: 'absolute',
                         top: 30,
                         left: '30%'
                         }}>
                NUEVO RESTAURANTE
            </h5>
            <label style={{
                            color:'black',
                            display: 'block',
                            fontfamily: 'Roboto-Light',
                            fontsize: '14px',
                            fontweight: '300',
                            fontstretch: 'normal',
                            fontstyle: 'normal',
                            lineheight: '1.14',
                            letterspacing: '0.35px',
                            textAlign: "left",
                            margin: '30px'}}>
                    Completa los datos solicitados para agregar un restaurante.
            </label>
            <div style={{
                borderBottom: "2px solid #c8c8c8",
                height: "0px",
                textAlign: "left",
                padding: "0 24px"}}>
            </div>
            <div style={{display:'flex', flexDirection:'column', alignItems: "center", padding: "19px 30px 19px 33px"}}>
                <TextBoxComponent placeholder="Nombre de restauramte" type="text" value={restaurantName} {...restaurantNameValidation} onChange={handleRestaurantNameChange}/>        
                <TextBoxComponent placeholder="Nombre de dueño" type="text" value={ownerName} style={{marginTop:'20px'}} {...ownerNameValidation} onChange={handleOwnerNameChange}/>
                <TextBoxComponent placeholder="Correo electrónico" type="text" value={email} style={{marginTop:'20px'}} {...emailValidation} onChange={handleEmailChange}/>
                {/* <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end', width:'100%', marginTop:'20px'}}>
                    <TextBoxComponent placeholder="Contraseña" type="text" {...passwordValidation} onChange={handlePasswordChange}/>
                    <button className="puerta21-btn" style={{position:'relative', 
                                                                right:15,
                                                                bottom: 45, 
                                                                width:"80px", 
                                                                height:"24px", 
                                                                fontSize:"12px", 
                                                                textAlign:"center", 
                                                                paddingBottom:"0px",
                                                                paddingTop:"0px",
                                                                paddingLeft:"0px",
                                                                paddingRight:"0px", 
                                                                boxShadow:"none"}}>
                        GENERAR
                    </button>
                </div> */}
                <div style={{marginTop:'20px', width:'100%'}}>
                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input
                            style={{width:'100%'}}
                            {...getInputProps({
                                placeholder: 'Buscar direccion ...',
                                className: 'puerta21-select col-12 d-block location-search-input',
                            })}
                            />
                            <div className="autocomplete-dropdown-container">
                            {loading && <div>Buscando...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                                );
                            })}
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
                    <div style={{display:'flex'}}>
                        <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={directionValidation}>Selecciona un elemento de la lista</label>
                        <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={addresValidation}>Campo requerido</label>
                    </div>
                    {/* <TextBoxComponent placeholder="Dirección" type="text" {...directionValidation} onChange={handleDirectionChange}/> */}
                </div>
                <select className={"puerta21-select col-12 d-block"} style={{
                                border:"none",
                                borderBottom:"1px solid black",
                                marginTop:'20px',
                                marginBottom:'10px'
                            }} onChange={(e) => {handleZone(e)}}>
                                <option selected>Zona</option>
                                {
                                    zoneCatalog.map(z =>(
                                        <option value={z.encrypt_id}>{z.name}</option>
                                    ))
                                }
                </select>
                <div style={{display:'flex', width:'100%', marginTop:'3px'}}>
                    <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={zoneValidation}>Campo requerido</label>
                </div>
                <Select
                    onChange={handlekitchen}
                    placeholder="Tipo de comida"
                    isMulti
                    name="kitchen"
                    options={kitchenCatalog}
                    className="puerta21-select col-12 d-block basic-multi-select"
                    classNamePrefix="select"
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            neutral30: 'white',
                            neutral20: 'white',
                            primary: 'white',
                        },
                    })}
                />
                <div style={{display:'flex', width:'100%', marginTop:'3px'}}>
                    <label style={{color:'#f44336', fontSize:'0.75rem', fontFamily:'Roboto'}} hidden={kitchensValidation}>Almenos una comida es requerida</label>
                </div>
                <div style={{display:'flex', flexDirection:'column', width:'100%', marginTop:'30px'}}>
                    <label style={{textAlign:'initial'}}>Rango de precios</label>
                    <div style={{display:'flex', justifyContent:'flex-start', marginLeft:'-6px'}}>
                        <Rating
                            onClick={setRatingComponent}
                            ratingValue={rating}
                            transition
                            size={25}
                            stars={5}
                            fillColor={'black'}
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z"/>
                            </svg>
                        </Rating>
                    </div>
                    {/* <PriceComponent style={{width:'150px', height:'35px'}} value={0} edit={true} size={30}/> */}
                </div>
                <div style={{display:'flex', width:'100%', marginTop:'30px'}}>
                    <div className="row">
                        <div className="col-6" style={{textAlign:"initial"}}>
                            <CheckBoxComponent text="Valet parking" onChange={handleValet}/>
                            <CheckBoxComponent text="Acceso para silla de ruedas" onChange={handleAccess} style={{paddingTop:'38px', width:'max-content'}}/>
                            <CheckBoxComponent text="Desayuno" onChange={handleMenu13}/>

                            <CheckBoxComponent text="Parqueo cerca" onChange={handleMenu7}/>
                            <CheckBoxComponent text="Salones privados" onChange={handleMenu8}/>

                        </div>
                        <div className="col-6" style={{textAlign:"initial"}}>
                            <CheckBoxComponent text="Miembro de Aderes: Asociación Dominicana de restaurantes" onChange={handleMenu2}/>
                            <CheckBoxComponent text="Certificado Ministerio de Turismo" onChange={handleMenu3}/>
                            <CheckBoxComponent text="Terraza" onChange={handleMenu5}/>
                            <CheckBoxComponent text="Área de juegos para niños" onChange={handleMenu9}/>
                            <CheckBoxComponent text="Posibilidad de reservar el restaurante completo para eventos" onChange={handleMenu12}/>
                            <CheckBoxComponent text="Cigar" onChange={handleMenu14}/>
                            <CheckBoxComponent text="Reserva Especial" onChange={handleMenu15}/>



                        </div>
                        
                        <div className="col-6" style={{textAlign:"initial"}}>
                            <CheckBoxComponent text="Menú vegano" onChange={handleMenu}/>
                            <CheckBoxComponent text="Admiten mascotas" onChange={handleMenu4}/>
                            <CheckBoxComponent text="Área para fumar" onChange={handleMenu6}/>
                            <CheckBoxComponent text="Cena el 24 de diciembre" onChange={handleMenu10}/>
                            <CheckBoxComponent text="Cena el 31 de diciembre" onChange={handleMenu11}/>




                        </div>
                    </div>
                </div>
                <div style={{width:'100%', marginTop:'30px'}}>
                    <button className="btn btn-primary btn-lg mt-5" type="button" style={{width:'100%', height:'40px'}} onClick={() => {addNewRestaurant()}}>
                        AGREGAR
                    </button>
                </div>
            </div>
        </article>
    );

    return (
        isDesktopOrLaptop ? desktop() : movile()
    )
}


export default NewRestaurant
